import React, { Component, useState } from 'react'
import './leadGenerationFormStyle.scss';
import { Button } from '@s8/react-component-library';
import DataService from '../../services/DataService';
import { UserContact } from '../../models/models';
import { off } from 'process';


interface State {

}

interface Props {
  isS8Style: boolean;
}

export default class LeadGenerationForm extends Component<Props,State> {

  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <a href={this.props.isS8Style ? "https://synpulse8.com/contact-us" : "https://www.synpulse.com/en/contact"} target='blank'>
        <div className={this.props.isS8Style ? 'lead-gen-form-container s8-container' : 'lead-gen-form-container'}>
          <div className={this.props.isS8Style ? 'lead-gen-form-title-s8 lead-gen-form-title':'lead-gen-form-title'}>
            <h4>Contact Us</h4>
          </div>
        </div>
      </a>
    )
  }
}
