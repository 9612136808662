import React, { Component, useState } from 'react';
import Header from './Header';
import './DirectoryStyle.scss';
import '../../App.scss';
import EmployeeList from './EmployeeList';
import { EMPLOYEES } from '../../mock-data';
import { CircularProgress, ToggleButton, ToggleButtonGroup } from '@mui/material';
import DataService from '../../services/DataService';
import { Banner, Button, Input, Popup, Separator, Switch } from '@s8/react-component-library';
import SearchIcon from '@mui/icons-material/Search';
import Pagination from './Pagination';
import AuthService from '../../services/AuthService';
import FilterComponent from '../filter/filterComponent';
import sortIcon from '../../assets/sortIcon.svg';
import ScrollToTopButton from '../scrollToTopButton/srollToTopButton';


interface DirectoryState {
  employees: any;
  currentPage: number;
  isS8Style: boolean;
  isLoading: boolean;
  message: string;
  showSearchIcon: boolean;
}

const messageValue = 'No results ...';

export default class Directory extends Component<{},DirectoryState> {
  allEmployees: any[] = [];
  isAscOrdered: boolean = true;

  constructor(props:any) {
    super(props)
    this.state = {
      employees: [],
      currentPage: 1,
      isS8Style: false,
      isLoading: false,
      message: '',
      showSearchIcon: true
    }

    this.handleFilter = this.handleFilter.bind(this);
    this.switchStyle = this.switchStyle.bind(this);
    this.getFilteredEmployeeData = this.getFilteredEmployeeData.bind(this);
  }

  componentDidMount(): void {
    DataService.getToken();
    localStorage?.getItem('theme') == 'dark' ? this.setState({isS8Style: true}) : this.setState({isS8Style:false});
    AuthService.getCurrentUser().then((response) => {
      if (!response) {
        AuthService.logIn("");
      }
    });
    this.getEmployeeData();
  }

  switchStyle() {
    this.state.isS8Style ? localStorage.setItem('theme','light') : localStorage.setItem('theme','dark');
    this.setState({
      isS8Style: !this.state.isS8Style
    })
  }

  handleSortChanged = () => {
    this.isAscOrdered = !this.isAscOrdered;
    this.sortEmployees();
  }

  sortEmployees = () => {
    if (this.isAscOrdered) {
      this.setState({employees: this.state.employees.sort((p1: any, p2: any) => p1.displayName > p2.displayName ? 1 : -1)});
    } else {
      this.setState({employees: this.state.employees.sort((p1: any, p2: any) => p1.displayName > p2.displayName ? -1 : 1)});
    }
  }

  handleFilter = (value: string) => {
    value.length > 0 ? this.setState({showSearchIcon: false}) : this.setState({showSearchIcon: true});
    !this.getSearchResult(value).length ? this.setState({message:messageValue}): this.setState({message:''});
    this.setState({employees: this.getSearchResult(value)});
  }

  getSearchResult(value: string) {
    const exactMatches = this.allEmployees.filter(e => e.displayName?.toLowerCase().startsWith(value.toLowerCase()));
    const otherMatches = this.allEmployees.filter(e =>
      e.email?.toLowerCase().includes(value.toLowerCase()) ||
      e.jobTitle?.toLowerCase().includes(value.toLowerCase()) ||
      e.phoneNumber?.toLowerCase().includes(value.toLowerCase()) ||
      e.locationName?.toLowerCase().includes(value.toLowerCase()) ||
      e.department?.toLowerCase().includes(value.toLowerCase()) ||
      e.employeeId?.toLowerCase().includes(value.toLowerCase()) ||
      e.officeLocation?.toLowerCase().includes(value.toLowerCase()) ||
      e.locationId?.toLowerCase().includes(value.toLowerCase()) ||
      e.vertical?.toLowerCase().includes(value.toLowerCase()) ||
      e.shortVertical?.toLowerCase().includes(value.toLowerCase()) ||
      e.jobPosition?.toLowerCase().includes(value.toLowerCase()) ||
      e.focusRole?.toLowerCase().includes(value.toLowerCase()) ||
      e.jobLevel?.toLowerCase().includes(value.toLowerCase())
    ).filter(e => !exactMatches.includes(e));

    return [...exactMatches, ...otherMatches];
  }

  getEmployeeData() {
      this.setState({isLoading: true});
      this.setState({message: ''});
      DataService.getDirectory().then(
        (response) => {
          !response.length && this.setState({message: messageValue});
          this.allEmployees = response;
          this.setState({
            isLoading: false,
            employees: response,
          })
        }
      )
  }

  getFilteredEmployeeData(filter: any) {
    this.setState({isLoading: true});
    this.setState({message: ''});
    DataService.filterUsers(filter).then(
      (response) => {
        !response.length && this.setState({message: messageValue});
        this.allEmployees = response;
        this.setState({
          isLoading: false,
          employees: response,
        })
      }
    )
  }

  render() {
    return (
      <div className='directory' style={{backgroundColor: this.state.isS8Style ? '#121A21': ''}}>
          <Header handleSort={this.handleSortChanged} handleFilter={this.handleFilter} switchStyle={this.switchStyle} isS8Style={this.state.isS8Style}></Header>
          <div className={window.innerWidth > 700 ? 'directory-search directory-search-offset' : 'directory-search'}>
              <form className='search-form'>
                <div className='search' >
                  {this.state.showSearchIcon && <i className='search-icon' style={{color: this.state.isS8Style ? 'white' : 'black'}}><SearchIcon/></i>}
                  <input className={this.state.isS8Style ? 'search-field-s8 search-field' : 'search-field'} style={{backgroundColor: this.state.isS8Style ? '#2F353A' : '#F3F3F4'}} placeholder='Search here' onChange={(event) => this.handleFilter(event.target.value)}/>
                </div>
                <i className='sort-icon' onClick={this.handleSortChanged}><img className={this.state.isS8Style ? "sort-icon-img-s8" : ""} src={sortIcon}></img></i>
              </form>
            </div>
          <FilterComponent isS8Style={this.state.isS8Style} onFilter={this.getFilteredEmployeeData}></FilterComponent>
          {this.state.message && <div className='w-100 d-flex justify-content-center message-employee' style={{color:this.state.isS8Style ? 'white' : 'black'}}><b>{this.state.message}</b></div>}
          {
            !this.state.isLoading ?

              <EmployeeList
                employees={this.state.employees}
                isS8Style={this.state.isS8Style}
              />

              :
            <div className='w-100 d-flex justify-content-center loader-employee'>
              <CircularProgress style={{color:this.state.isS8Style ? 'white' : 'black'}}/>
            </div>
          }
          <div>
            <ScrollToTopButton/>
          </div>
          <div className='spacer'></div>
      </div>
    )
  }
}
