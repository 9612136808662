import React, { Component } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import VCardProfile from './components/VCardProfile/VCardProfile';
import Directory from './components/directory/Directory';
import AuthService from './services/AuthService';

interface State {
  isLoggedIn: boolean;
}

export default class AppRouter extends Component<{},State> {

  constructor(props: any) {
    super(props)

    this.state = {
      isLoggedIn: false
    }
  }


  componentDidMount(): void {
    AuthService.getCurrentUser().then((response) => {
      if (response) {
        this.setState({
          isLoggedIn: true
        })
      }
    })
  }

  render() {

    const router = createBrowserRouter([
        {
          path: "/",
          element: <Directory/>
        },
        {
          path: "/directory",
          element: <Directory/>
        },
        {
          path:'/:theme/:email',
          element: <VCardProfile/>
        }
      ]);

    return (
      <div>
        <RouterProvider router={router} />
      </div>
    )
  }
}
