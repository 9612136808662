class HelperService {

    getPresenceColor(presence: any) {
        switch(presence) {
            case "Available":
                return "#4a9f30";
            case "Offline":
                return "gray";
            case "Away":
            case "Inactive":
                return "#e0a639";
            case "OutOfOffice":
            case "OffWork":
                return "purple";
            case "Busy":
            case "InAMeeting":
            case "InACall":
            case "DoNotDisturb":
            case "Presenting":
                return "#c1423f";
            default:
                return "#c1423f";
        }
    }

    insertSpaceBeforeCapital(inputString: string) {
        if(inputString) {
            const formattedString = inputString.replace(/([A-Z])/g, ' $1');
            return formattedString;
        }
        return inputString;
    }

}

export default new HelperService();