import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Component } from "react";
import './filterStyle.scss';

interface Props {
    isS8Style: boolean;
    onFilter: (options: any) => any;
}

interface State {
    displayName: '',
    jobTitle: '',
    jobPosition: '',
    email: '',
    employeeId: '',
    vertical: '',
    department: '',
    locationId: '',
    locationName: '',
    jobLevel: '',
    birthday: '',
}

const levelOptions = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "X"];
const verticalOptions = ["Management Consulting", "Professional Internal Services", "Center of Excellence", "Synpulse8"];
const departmentOptions = [
    "Banking",
    "Insurance",
    "Finance",
    "Legal & Compliance",
    "People & Culture",
    "Talent Acquisition",
    "L&D",
    "Corporate Communications",
    "Marketing",
    "BDM",
    "Knowledge Management",
    "PIS Transformation",
    "Operations",
    "IT",
    "Center of Excellence",
    "Tech - Build",
    "Tech - Run",
    "Technology Advisory Services",
    "Product Manufacturing",
    "Technology 3rd party Services",
    "Managed Platform"
];
const positionOptions = [
    "Associate Consultant",
    "Consultant",
    "Senior Consultant",
    "Manager",
    "Associate Partner",
    "Managing Director",
    "Partner",
    "Senior Partner",
    "Managing Partner",
    "SA",
    "Junior Specialist",
    "Specialist",
    "Senior Specialist",
    "Regional/Topic Head",
    "Group Head",
    "C-Level",
    "Entry",
    "Intermediary",
    "Experienced",
    "Mastered",
    "Distinguished",
    "Intern"
];
const locationIdOptions = [
    "SYNAE",
    "SYNAU",
    "SYNCA",
    "SYNCH",
    "SYNCN",
    "SYNDE",
    "SYNFR",
    "SYNHK",
    "SYNID",
    "SYNIN",
    "SYNINTL",
    "SYNLX",
    "SYNMY",
    "SYNPH",
    "SYNSG",
    "SYNSK",
    "SYNTH",
    "SYNTW",
    "SYNUK",
    "SYNUS"
];

export default class FilterComponent extends Component<Props, State> {
    initialState = {
        displayName: '',
        jobTitle: '',
        jobPosition: '',
        email: '',
        employeeId: '',
        vertical: '',
        department: '',
        locationId: '',
        locationName: '',
        jobLevel: '',
        birthday: '',
        currentDate: new Date().toISOString(),
    };

    constructor(props:Props) {
        super(props);

        this.state = {
            displayName: '',
            jobTitle: '',
            jobPosition: '',
            email: '',
            employeeId: '',
            vertical: '',
            department: '',
            locationId: '',
            locationName: '',
            jobLevel: '',
            birthday: '',
        }

        this.handleChange = this.handleChange.bind(this);
        this.handDropdownChange = this.handDropdownChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onReset = this.onReset.bind(this);
    }

    handleChange(event: any) {
        event.preventDefault();
        const {name, value} = event.target;
        this.initialState = {
            ...this.initialState,
            [name]:value
        };
        this.setState({...this.state, [name]:value});
    }

    handDropdownChange(event: any, value: any, name: string) {
        this.initialState = {
            ...this.initialState,
            [name]:value
        };

        this.setState({...this.state, [name]:value});
    }

    onReset(event: any) {
        this.initialState = {
            displayName: '',
            jobTitle: '',
            jobPosition: '',
            email: '',
            employeeId: '',
            vertical: '',
            department: '',
            locationId: '',
            locationName: '',
            jobLevel: '',
            birthday: '',
            currentDate: new Date().toISOString(),
        };

        this.setState({jobLevel: ""});
        this.setState({jobPosition: ""});
        this.setState({vertical: ""});
        this.setState({department: ""});
        this.setState({locationId: ""});
        this.setState({birthday: ""});

        this.props.onFilter(this.initialState)
    }

    onSubmit(event: any) {
        event.preventDefault();
        this.props.onFilter(this.initialState)
    }

    render() {
        return (
            <div>
                <Accordion className={this.props.isS8Style ? "advance-search-container-s8" : "advance-search-container"} style={{ boxShadow: "none" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={this.props.isS8Style ? "white-text" : ""} />}
                        aria-controls="panel1a-content"
                    >
                        <span className={this.props.isS8Style ? "white-text" : ""}>Advanced search</span>
                    </AccordionSummary>
                    <AccordionDetails className="form-container">
                        <form className="filter-form" onSubmit={this.onSubmit} onReset={this.onReset}>
                            <div className="filters-container">
                                <div className="input-container">
                                    <label className={this.props.isS8Style ? "input-title-s8" : "input-title"}>Full Name</label>
                                    <input name="displayName" onChange={this.handleChange} className={this.props.isS8Style ? "input-s8" : "input"} type="text" />
                                </div>
                                <div className="input-container">
                                    <label className={this.props.isS8Style ? "input-title-s8" : "input-title"}>Job Title</label>
                                    <input name="jobTitle" onChange={this.handleChange} className={this.props.isS8Style ? "input-s8" : "input"} type="text" />
                                </div>
                                <div className="input-container">
                                    <label className={this.props.isS8Style ? "input-title-s8" : "input-title"}>Position Level</label>
                                    <Autocomplete
                                        id="jobLevel"
                                        onInputChange={(event, value) => this.handDropdownChange(event, value, "jobLevel")}
                                        options={levelOptions}
                                        freeSolo={true}
                                        value={this.state.jobLevel}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref} className="input-dropdown">
                                                <input {...params.inputProps}
                                                    name="jobLevel"
                                                    className={this.props.isS8Style ? "input-s8" : "input"}
                                                    type="text"
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="input-container">
                                    <label className={this.props.isS8Style ? "input-title-s8" : "input-title"}>Employee ID</label>
                                    <input name="employeeId" onChange={this.handleChange} className={this.props.isS8Style ? "input-s8" : "input"} type="text" />
                                </div>
                                <div className="input-container">
                                    <label className={this.props.isS8Style ? "input-title-s8" : "input-title"}>Job Position</label>
                                    <Autocomplete
                                        id="jobPosition"
                                        freeSolo={true}
                                        onInputChange={(event, value) => this.handDropdownChange(event, value, "jobPosition")}
                                        options={positionOptions.sort()}
                                        value={this.state.jobPosition}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref} className="input-dropdown">
                                                <input {...params.inputProps}
                                                    name="jobPosition"
                                                    className={this.props.isS8Style ? "input-s8" : "input"}
                                                    type="text"
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="input-container">
                                    <label className={this.props.isS8Style ? "input-title-s8" : "input-title"}>Vertical</label>
                                    <Autocomplete
                                        id="vertical"
                                        freeSolo={true}
                                        onInputChange={(event, value) => this.handDropdownChange(event, value, "vertical")}
                                        options={verticalOptions.sort()}
                                        value={this.state.vertical}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref} className="input-dropdown">
                                                <input {...params.inputProps}
                                                    name="vertical"
                                                    className={this.props.isS8Style ? "input-s8" : "input"}
                                                    type="text"
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="input-container">
                                    <label className={this.props.isS8Style ? "input-title-s8" : "input-title"}>Department</label>
                                    <Autocomplete
                                        id="department"
                                        freeSolo={true}
                                        onInputChange={(event, value) => this.handDropdownChange(event, value, "department")}
                                        options={departmentOptions.sort()}
                                        value={this.state.department}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref} className="input-dropdown">
                                                <input {...params.inputProps}
                                                    name="department"
                                                    className={this.props.isS8Style ? "input-s8" : "input"}
                                                    type="text"
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="input-container">
                                    <label className={this.props.isS8Style ? "input-title-s8" : "input-title"}>Location ID</label>
                                    <Autocomplete
                                        id="locationId"
                                        freeSolo={true}
                                        onInputChange={(event, value) => this.handDropdownChange(event, value, "locationId")}
                                        options={locationIdOptions.sort()}
                                        value={this.state.locationId}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref} className="input-dropdown">
                                                <input {...params.inputProps}
                                                    name="locationId"
                                                    className={this.props.isS8Style ? "input-s8" : "input"}
                                                    type="text"
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="input-container">
                                    <label className={this.props.isS8Style ? "input-title-s8" : "input-title"}>Location Name</label>
                                    <input name="locationName" onChange={this.handleChange} className={this.props.isS8Style ? "input-s8" : "input"} type="text" />
                                </div>
                                <div className="input-container">
                                    <label className={this.props.isS8Style ? "input-title-s8" : "input-title"}>Birthday</label>
                                    <Select
                                        id="birthday"
                                        name="birthday"
                                        value={this.state.birthday}
                                        onChange={this.handleChange}
                                        className={this.props.isS8Style ? "input-s8" : "input"}
                                        style={{fontFamily: "Outfit", color: "#B7B9BB"}}
                                    >
                                        <MenuItem value={"CURRENT_DAY"} style={{fontFamily: "Outfit"}}>Current Day</MenuItem>
                                        <MenuItem value={"CURRENT_WEEK"} style={{fontFamily: "Outfit"}}>Current Week</MenuItem>
                                        <MenuItem value={"CURRENT_MONTH"} style={{fontFamily: "Outfit"}}>Current Month</MenuItem>
                                    </Select>
                                </div>
                            </div>
                            <div className="bottom-container">
                                <div className="remove-filters-btn">
                                    <button type="reset" className={this.props.isS8Style ? "white-text" : ""}>Remove Filters x</button>
                                </div>
                                <div>
                                    <button className={this.props.isS8Style ? "submit-filters-btn-s8" : "submit-filters-btn"} type="submit">Filtered Search</button>
                                </div>
                            </div>
                        </form>
                    </AccordionDetails>
                </Accordion>
            </div>
          )
    }
}