import axios from 'axios';

class DataService {
    apiUrl: any;
    token: string = "";
    constructor() {
        this.apiUrl = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        });
    }

    async logIn() {
        try {
            const response = await this.apiUrl.get('/custom-login');
            document.location = process.env.REACT_APP_API_URL + response.data;
            return response.data;
        } catch (error:any) {
            throw new Error('Error fetching data:' + error.message);
        }
    }

    async getPhoto(email: any) {
        try {
            const response = await this.apiUrl.get(`/v1/qr/public/photo?id=${email}`, {withCredentials: true});
            return response.data;
        } catch (error:any) {

        }
    }

    async getPhotoFromDB(email: any) {
        try {
            const response = await this.apiUrl.get(`/v1/qr/public/photo-fast?id=${email}`, {withCredentials: true});
            return response.data;
        } catch (error:any) {

        }
    }

    async getPhotoDirectory(email: any) {
        try {
            const response = await this.apiUrl.get(`/v1/directory/secured/photo?email=${email}`, {withCredentials: true});
            return response.data;
        } catch (error:any) {

        }
    }

    async getDirectory() {
        try {
            const response = await this.apiUrl.get(`/v1/directory/secured/get-all`, {withCredentials: true});
            return response.data;
        } catch (error:any) {
            throw new Error('Error fetching data:' + error.message);
        }
    }

    async getInternal(email: any) {
        try {
            const response = await this.apiUrl.get(`/v1/qr/secured/info?id=${email}`, {withCredentials: true});
            return response.data;
        } catch (error:any) {
            throw new Error('Error fetching data:' + error.message);
        }
    }

    async getExternal(email: any) {
        try {
            const response = await this.apiUrl.get(`/v1/qr/public/info?id=${email}`, {withCredentials: true});
            return response.data;
        } catch (error:any) {
            throw new Error('Error fetching data:' + error.message);
        }
    }

    async getPresence(email: any) {
        try {
            const response = await this.apiUrl.get(`/v1/qr/secured/presence?id=${email}`, {withCredentials: true});
            return response.data;
        } catch (error:any) {
            throw new Error('Error fetching data:' + error.message);
        }
    }

    async getToken() {
        try {
            const response = await this.apiUrl.get(`/secure/token`, {withCredentials: true});
            this.token = response.data;
        } catch (error:any) {
            throw new Error('Error fetching data:' + error.message);
        }
    }

    async filterUsers(filter:any){
        const headers = {
            'Accept':'application/json',
            'Content-Type':'application/json',
            'X-Xsrf-Token': this.token
        }
        try {
            const response = await this.apiUrl.post(`/v1/directory/secured/with-filters`, JSON.stringify(filter),
                {
                    withCredentials: true,
                    headers: headers
            });
            return response.data;
        } catch (error:any) {
            throw new Error('Error fetching data:' + error.message);
        }
    }

}

export default new DataService();

