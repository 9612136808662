import React, { Component } from 'react';
import axios from 'axios';

class AuthService {
    apiUrl: any;
    baseUrl = process.env.REACT_APP_API_URL;
    constructor() {
        this.apiUrl = axios.create({
            baseURL: this.baseUrl
        });
    }

    async logIn(redirectUri: string) {
        try {
            const response = await this.apiUrl.get('/custom-login');            
            document.location = this.baseUrl + response.data + `?redirect_uri=${redirectUri}`;
            return response.data;
        } catch (error:any) {
            throw new Error('Error fetching data:' + error.message);
        }
    }

    
    async getCurrentUser() {        
        try {
            const response = await this.apiUrl.get('/current-user',{withCredentials: true});            
            return response.data;
        } catch (error:any) {
        }
    }
}

export default new AuthService();