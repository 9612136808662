import React, { Component } from 'react'
import HelperService from '../../services/HelperService';
import { Tooltip } from '@mui/material';
import './teamsStatusStyle.scss';

interface Props {
    presence: string;
    class?:string;
}

interface State {

}

export default class teamsStatus extends Component<Props, State> {

    constructor(props: any) {
        super(props);
    }

  render() {
    return (
      <div>
        <Tooltip title={HelperService.insertSpaceBeforeCapital(this.props.presence)}>
            <div className={`circle-status ${this.props?.class}`} style={{backgroundColor: HelperService.getPresenceColor(this.props.presence)}}></div>
        </Tooltip>
      </div>
    )
  }
}
