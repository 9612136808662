enum Fields {
    Name,
    DisplayName,
    PhoneNumber,
    Email,
    Organization,
    Title,
    Photo
}

class VCardService {
    async downloadContact(contact: any) {
        var vcard = "BEGIN:VCARD\nVERSION:2.1"
        + this.getAttribute(Fields.Name, contact.surname + ";" + contact.givenName)
        + this.getAttribute(Fields.DisplayName, contact.displayName)
        + this.getAttribute(Fields.PhoneNumber, contact.phoneNumber)
        + this.getAttribute(Fields.Email, contact.email)
        + this.getAttribute(Fields.Organization, contact.entityName[0])
        + this.getAttribute(Fields.Title, contact.jobTitle)
        + this.getAttribute(Fields.Photo, contact.photo)
        + "\nEND:VCARD";

        var blob = new Blob([vcard], { type: "text/vcard" });

        const newLink = document.createElement('a');
        newLink.download = contact.displayName + ".vcf";
        newLink.textContent = contact.displayName;
        newLink.href = window.URL.createObjectURL(blob);

        newLink.click();
    }

    getAttribute(field: Fields, value: string) {
        if (value === undefined || !value) {
            return "";
        }
        switch (field) {
            case Fields.Name:
                return `\nN;CHARSET=utf-8:${value}`
            case Fields.DisplayName:
                return `\nFN;CHARSET=utf-8:${value}`;
            case Fields.PhoneNumber:
                return `\nTEL;TYPE=work,voice:${value}`;
            case Fields.Email:
                return `\nEMAIL:${value}`;
            case Fields.Organization:
                return `\nORG:${value}`;
            case Fields.Title:
                return `\nTITLE:${value}`;
            case Fields.Photo:
                return `\nPHOTO;JPEG;ENCODING=BASE64:${value}`;
            default:
                return "";
         }
    }
}

export default new VCardService();