import React, { Component, useEffect, useState } from 'react'
import logo from '../../assets/logo.png';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { Separator, Switch } from '@s8/react-component-library';
import logo_s8 from '../../assets/logo-s8.png';
import sortIcon from '../../assets/sortIcon.svg';

const Header = ({switchStyle, isS8Style}:any) => {

  return (
      <>
        {
          window.innerWidth > 800
            ?
            <div className={window.innerWidth > 800 ? 'header' : 'header-mobile'}>
            <div className='header-bar pb-3' style={{borderBottom: isS8Style ? 'solid 0.08rem #2F353A' : 'solid 0.08rem #E7E8E8'}}>
              <div>
                {isS8Style ? <img className='logo-img-s8' src={logo_s8}></img> : <img className='logo-img' src={logo}></img>}
              </div>
              <div className="theme-toggle">
                <div className='style-switch d-flex justify-content-center'>
                    <div className='me-3' style={{color: isS8Style ? 'white' : ''}}>Synpulse</div>
                      <div>
                      <Switch
                              id="switch"
                              onClick={() => switchStyle()}
                              size="medium"
                              enabled={isS8Style}
                      />
                      </div>
                      <div className='ms-3' style={{color: isS8Style ? 'white' : ''}}>Synpulse 8</div>
                    </div>
                </div>
            </div>
          </div>
        :
          <div>
            <div className='p-5 w-100  d-flex flex-column justify-content-center align-items-center'>
                {isS8Style ? <img className='logo-img-s8' src={logo_s8}></img> : <img className='logo-img' src={logo}></img>}
                <div className='style-switch d-flex justify-content-center w-100 mt-2'>
                  <div className='ms-2 me-2 switch-font-sm' style={{color: isS8Style ? 'white' : ''}}>Synpulse</div>
                    <div className='d-flex flex-column justify-content-center'>
                      <Switch
                              id="switch"
                              onClick={() => switchStyle()}
                              size="small"
                              enabled={isS8Style}
                      />
                    </div>
                  <div className='ms-2 switch-font-sm' style={{color: isS8Style ? 'white' : ''}}>Synpulse 8</div>
                </div>
            </div>
          </div>

        }
      </>
    )
  }

export default Header;
